//scroll-up and callback button
$(document).ready(function () {
  $("body").append('<a href="#" class="btn-fixed btn-call house-animation__button" id="fixedCall"></a>')
  $("#fixedCall").append('<i class="fa fa-phone" aria-hidden="true"></i>')
  $("body").append('<a href="#" class="btn-scroll-up" id="scrollUpBtn"></a>')
  $("#scrollUpBtn").append('<i class="fa fa-chevron-up" aria-hidden="true"></i>')
  $("#scrollUpBtn").click(function () {
    $("html").animate({ scrollTop: 0 }, 1500)
    $("body").animate({ scrollTop: 0 }, 1500)
  })

  $(window).scroll(function () {
    if ($(window).scrollTop() > 350) {
      $("#scrollUpBtn").addClass("active-btn")
      $("#fixedCall").addClass("active-call")
    } else {
      $("#scrollUpBtn").removeClass("active-btn")
      $("#fixedCall").removeClass("active-call")
    }
  })

  //active menu item

  var lastId,
    topMenu = $("#top-menu"),
    topMenuHeight = topMenu.outerHeight() + 15,
    menuItems = topMenu.find("a"),
    scrollItems = menuItems.map(function () {
      var item = $($(this).attr("href"))
      if (item.length) {
        return item
      }
    })

  menuItems.click(function (e) {
    var href = $(this).attr("href"),
      offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: offsetTop
        },
        300
      )
    e.preventDefault()
  })

  $(window).scroll(function () {
    var fromTop = $(this).scrollTop() + topMenuHeight
    var cur = scrollItems.map(function () {
      if ($(this).offset().top < fromTop) return this
    })
    cur = cur[cur.length - 1]
    var id = cur && cur.length ? cur[0].id : ""

    if (lastId !== id) {
      lastId = id
      menuItems
        .parent()
        .removeClass("active-item")
        .end()
        .filter("[href='#" + id + "']")
        .parent()
        .addClass("active-item")
    }
  })

  //pop-up form

  $(".btn-call").click(function showPopUp() {
    $(".pop-up").addClass("show")
  })

  $(".fa-times-circle").click(function hidePopUp() {
    $(".pop-up").removeClass("show")
  })

  function showMessage() {
    setTimeout(function () {
      $(".pop-up-message").show(300)
      $(".pop-up-container").hide()
    }, 1500)
    setTimeout(function () {
      $(".pop-up-message").hide()
      $(".pop-up").removeClass("show")
      $(".pop-up-container").show(2000)
    }, 5000)
  }

  // Send form ajax

  $("#pop-up-form").submit(function () {
    var form = $(this)
    var error = false
    form.find('textarea, input[type="text"], input[type="tel"]').each(function () {
      if ($(this).val() == "") {
        $(".message").fadeTo(500, 1)
        setTimeout(function () {
          $(".message")
            .stop(false, false)
            .animate(
              {
                opacity: 0
              },
              500
            )
        }, 3000)
        error = true
      }
    })
    if (!error) {
      var data = form.serialize()
      $.ajax({
        type: "POST",
        url: "./build/js/mail.php",
        dataType: "json",
        data: data,
        beforeSend: function (data) {
          form.find(".btn-submit").attr("disabled", "disabled")
        },
        success: function (data) {
          if (data["error"]) {
            alert(data["error"])
            form.trigger("reset")
            console.log("erroe data")
          } else {
            form[0].reset()
            showMessage()
            console.log("success")
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          alert(xhr.status)
          alert(thrownError)
        },
        complete: function (data) {
          form.find(".btn-submit").prop("disabled", false)
          console.log("complete")
        }
      })
    }
    return false
  })

  //slick slider init

  $("#postSlider").slick()

  // lightgallery init

  lightGallery(document.getElementById("gallery"), {
    thumbnail: true,
    download: false,
    selector: ".gallery_item"
  })

  new WOW().init()

  lightGallery(document.getElementById("street-map-1"), {
    thumbnail: true,
    download: false,
    selector: ".sale__item-map"
  })

   lightGallery(document.getElementById("street-map-2"), {
    thumbnail: true,
    download: false,
    selector: ".sale__item-map"
  })


  lightGallery(document.getElementById("aniimated-thumbnials"), {
    thumbnail: true,
    download: false,
    selector: ".dom-photo_item"
  })

  lightGallery(document.getElementById("lightgallery"), {
    thumbnail: true,
    download: false,
    selector: ".dom-photo_item"
  })

  lightGallery(document.getElementById("house-plan"), {
    thumbnail: true,
    download: false,
    selector: ".dom-photo_item"
  })

  lightGallery(document.getElementById("sunny-plan"), {
    thumbnail: true,
    download: false,
    selector: ".sunny-plan__item"
  })

  lightGallery(document.getElementById("interiorLightBox"), {
    thumbnail: true,
    download: false,
    selector: ".interior-slider_item"
  })

  lightGallery(document.getElementById("finishedLightBox"), {
    thumbnail: true,
    download: false,
    selector: ".finished-house-slider_item"
  })

  lightGallery(document.getElementById("postSlider"), {
    thumbnail: true,
    download: false,
    selector: ".post-slider__item"
  })

  // change mobile menu button
  // if (window.innerWidth >= 1024) return false;

  $(".btn-menu-trigger").click(function () {
    $(this).toggleClass("is-active")
    $(".primary-navigation").toggleClass("is-active")
  })

  $("a[href^='tel:']").on("click", function () {
    gtag("event", "send", { event_category: "kliknomer", event_action: "button" })
    console.log("kliknomer")
    return true
  })

  var hash_raz = location.hash
  if (hash_raz && $(hash_raz).hasClass("promo")) {
    $("html, body").animate(
      {
        scrollTop: jQuery(hash_raz).offset().top - 80
      },
      500
    )
  }
})
